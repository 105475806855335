import React, {   } from "react";


import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';




function App() {
  return (
    
    <div>
     <Header/>
     <Home/>
      <Footer/> 
      </div>
    
  );
}

export default App;
